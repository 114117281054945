<template>
  <div>
    <h1 class="ml-8">{{ $t('home.title') }}</h1>
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>
